import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title={`The NFL DFS Daily Fantasy Football Blog`} description={`The NFL DFS Blog brings you news and updates relating to Daily Fantasy Football.`} />
      <div>
        <h1 style={{ display:`inline-block`,}}>
          The NFL DFS Daily Fantasy Football Blog
        </h1>
        <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div key={node.id}>
            <Link
              to={node.fields.slug}
              style={{ textDecoration: `none`, color: `inherit`}}
              >
              <h3
                style={{ marginBottom: `15px`}}>
                {node.frontmatter.title}{" "}
                <span style={{ color: `#bbb`}}>
                  — {node.frontmatter.date}
                </span>
              </h3>
              <p style={{ fontStyle: `italic`,  marginBottom: `10px`}}> - by the NFL DFS team</p>
              <p style={{ fontSize: `15px`, marginBottom: `10px`}}>{node.timeToRead + 0.5} minutes to read</p>
              <p>{node.excerpt}</p>
            </Link>
          </div>
        ))}
      </div>
      </Layout>
  )
}

export const query = graphql`
query {
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    totalCount
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
        excerpt
        timeToRead
        html
      }
    }
  }
}
`